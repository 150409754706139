<template>
    <div class="indexbody">
        <myhead :param="param" />
        <section>
            <mybanner :param="manbannner"/>
        </section>
        <section style="margin-top: 80px;">
            <div class="body1200">
                <div style="font-size: 32px;font-weight: bold;" v-html="this.gsGy.tb_title_sub"></div>
                <div style="font-size: 18px;line-height:30px;color: rgba(0,0,0,0.8);margin : 40px 50px 0 50px;" v-html="this.gsGy.tb_summary">
                </div>
            </div>
        </section>
        <section style="margin-top: 40px;">
            <div class="body1400"  style="display: flex;" v-if="businessData">
                <div class="zzcp" v-for="(n,i) of businessData.length " :key="'item'+businessData[i].tb_id">
                    <div class="zzcp1"  :style="i==(businessData.length-1)?'border-right: none;':'' " >
                        <div class="zzcp2">{{businessData[i].tb_title}}</div>
                        <div class="zzcp3">{{businessData[i].tb_summary}}</div>
                        <div class="zzcp2">{{businessData[i].tb_title_sub}}</div>
                    </div>
                </div>
            </div>
        </section>
        <section style="margin-top: 80px;">
            <div class="body1400">
                <div style="font-size: 36px;font-weight: bold">推<span class="tjcpLine" >荐产</span>品</div>
                <div style="margin-top: 100px;">
                    <div style="display: flex;">
                        <div style="justify-content: flex-end;width: 450px;height:440px;" v-if="tjcpData && tjcpData[0]" >
                            <img  :src="tjcpData[0].tb_image"  usemap="#tjcpmap0"  >
                            <map name="tjcpmap0"  id="tjcpmap0" >
                                <area shape="rect" coords="0,0,450,440" :href="tjcpData[0].tb_alias" target="_blank"  />
                            </map>
                        </div>
                        <div style="justify-content: flex-end;width: 925px;height:440px;overflow: hidden;margin-left: 25px" v-if="tjcpData && tjcpData[1]" >
                            <img :src="tjcpData[1].tb_image"  usemap="#tjcpmap1" >
                            <map name="tjcpmap1"  id="tjcpmap1" >
                                <area shape="rect" coords="0,0,925,440" :href="tjcpData[1].tb_alias" target="_blank"  />
                            </map>
                        </div>
                    </div>
                </div>
                <div style="margin-top: 25px;">
                    <div style="display: flex;">
                        <div style="justify-content: flex-end;width: 925px;height:440px;overflow: hidden;" v-if="tjcpData && tjcpData[2]">
                            <img  :src="tjcpData[2].tb_image"  usemap="#tjcpmap2" >
                            <map name="tjcpmap2"  id="tjcpmap2" >
                                <area shape="rect" coords="0,0,925,440" :href="tjcpData[2].tb_alias" target="_blank"  />
                            </map>
                        </div>
                        <div style="justify-content: flex-end;width: 450px;height:440px;overflow: hidden;margin-left: 25px" v-if="tjcpData && tjcpData[3]">
                            <img  :src="tjcpData[3].tb_image"  usemap="#tjcpmap3" >
                            <map name="tjcpmap3"  id="tjcpmap3" >
                                <area shape="rect" coords="0,0,450,440" :href="tjcpData[3].tb_alias" target="_blank"  />
                            </map>
                        </div>
                    </div>
                </div>
                <div v-if="tjcpData && (tjcpData.length>4)">
                <div style="margin-top: 25px;" v-for="(n,i) of Math.ceil((tjcpData.length-4)/3)" :key="'item'+tjcpData[3*i].tb_id">
                    <div style="display: flex;">
                        <div style="justify-content: flex-end;width: 450px;height:440px;overflow: hidden;" v-if="tjcpData[3*i+4]">
                            <img :src="tjcpData[3*i+4].tb_image"  :usemap="'#tjcpmap'+(3*i+4)" >
                            <map :name="'tjcpmap'+(3*i+4)"  :id="'tjcpmap'+(3*i+4)" >
                                <area shape="rect" coords="0,0,450,440" :href="tjcpData[3*i+4].tb_alias" target="_blank"  />
                            </map>
                        </div>
                        <div style="justify-content: flex-end;width: 450px;height:440px;overflow: hidden;margin-left: 25px" v-if="tjcpData[3*i+5]">
                            <img :src="tjcpData[3*i+5].tb_image" :usemap="'#tjcpmap'+(3*i+5)" >
                            <map :name="'tjcpmap'+(3*i+5)"  :id="'tjcpmap'+(3*i+5)" >
                                <area shape="rect" coords="0,0,450,440" :href="tjcpData[3*i+5].tb_alias"  target="_blank"  />
                            </map>
                        </div>
                        <div style="justify-content: flex-end;width: 450px;height:440px;overflow: hidden;margin-left: 25px" v-if="tjcpData[3*i+6]">
                            <img :src="tjcpData[3*i+6].tb_image" :usemap="'#tjcpmap'+(3*i+6)" >
                            <map :name="'tjcpmap'+(3*i+6)"  :id="'tjcpmap'+(3*i+6)" >
                                <area shape="rect" coords="0,0,450,440" :href="tjcpData[3*i+6].tb_alias"  target="_blank"  />
                            </map>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <section style="margin-top: 80px;">
            <div style="font-size: 36px;font-weight: bold">商用<span class="tjcpLine" >能源</span>方案
            </div>
            <div class="fangan" >
                <!--
                <div class="body1660">
                    <div style="height: 600px;display: flex;justify-content: center;align-items: center;">
                        <div style="width: 168px;height: 300px; background-color: rgba(255,255,255,0.9)">
                            <div>
                                <div style="font-size: 22px;font-weight: bold;line-height: 34px;margin-top: 30px;padding: 0px 20px 0px 20px;">
                                    {{businessSchemec.tb_title_sub}}
                                </div>
                                <div style="font-size: 14px;line-height: 18px;color: rgba(32,32,32,0.8);margin-top: 20px;margin-left: 24px;margin-right: 24px;text-align: left;padding: 0px 10px 0px 10px;">
                                    {{businessSchemec.tb_description}}
                                </div>
                            </div>
                            <div style="font-size: 14px;color: #d7000f;margin-top: 20px">
                                <a class="mgd" @click="goscheme(businessSchemec.tb_summary==businessSchemec.tb_content?businessSchemec.tb_summary:businessSchemec.tb_id)">查看更多</a>
                            </div>
                        </div>
                    </div>
                    <div style="height: 50px; ">
                    </div>
                    <div class="htable" style="margin-top: 30px;align-items: center;">
                        <div class="hcell" style="width: 130px;text-align: left;display: flex;">
                        </div>
                        <div  v-if="businessScheme[0]" :class="'hcell '+  businessScheme[0].tb_title_seo" style="margin-left: 0;" @click="businessSchemec=businessScheme[0];">
                            <div class="fa-txt">{{businessScheme[0].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[1]" :class="'hcell '+  businessScheme[1].tb_title_seo"  @click="businessSchemec=businessScheme[1];">
                            <div class="fa-txt">{{businessScheme[1].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[2]" :class="'hcell '+  businessScheme[2].tb_title_seo"  @click="businessSchemec=businessScheme[2];">
                            <div class="fa-txt">{{businessScheme[2].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[3]" :class="'hcell '+  businessScheme[3].tb_title_seo"  @click="businessSchemec=businessScheme[3];">
                            <div class="fa-txt">{{businessScheme[3].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[4]" :class="'hcell '+  businessScheme[4].tb_title_seo"  @click="businessSchemec=businessScheme[4];">
                            <div class="fa-txt">{{businessScheme[4].tb_title}}</div>
                        </div>

                        <div class="hcell htable" style="margin-left: 0;width: 130px;text-align: right;display: flex;justify-content:right">

                        </div>
                    </div>
                </div>
                -->

                <div class="body1400">
                    <div style="height: 524px; ">
                    </div>
                    <div class="htable" style="align-items: center;justify-content: space-between;font-size: 22px;color: #ffffff;">

                        <div  v-if="businessScheme[0]" :class="'hcell '+  businessScheme[0].tb_title_seo" style="margin-left: 0;" @click="goto( businessScheme[0].tb_title_sub,null);">
                            <div class="fa-txt">{{businessScheme[0].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[1]" :class="'hcell '+  businessScheme[1].tb_title_seo"  @click="goto( businessScheme[1].tb_title_sub,null);">
                            <div class="fa-txt">{{businessScheme[1].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[2]" :class="'hcell '+  businessScheme[2].tb_title_seo"  @click="goto( businessScheme[2].tb_title_sub,null);">
                            <div class="fa-txt">{{businessScheme[2].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[3]" :class="'hcell '+  businessScheme[3].tb_title_seo"  @click="goto( businessScheme[3].tb_title_sub,null);">
                            <div class="fa-txt">{{businessScheme[3].tb_title}}</div>
                        </div>
                        <div  v-if="businessScheme[4]" :class="'hcell '+  businessScheme[4].tb_title_seo"  @click="goto( businessScheme[4].tb_title_sub,null);">
                            <div class="fa-txt">{{businessScheme[4].tb_title}}</div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section style="margin-top: 80px;">
            <div style="font-size: 36px;font-weight: bold">新<span class="tjcpLine" >闻动</span>态</div>
            <div class="body1400 htable" style="margin-top: 100px;" >
                <div class="hcell" style="width: 675px;height:542px;overflow: hidden;background-color: #ededed" >
                    <div v-if="rowNews && rowNews[0]" >
                        <div style="width: 675px;height: 372px;background-color: dimgrey;overflow: hidden;">
                            <img :src="rowNews[0].tb_image" style="width: 675px;">
                        </div>
                        <div >
                        <div style="font-size: 18px;font-weight: bold;text-align: left;margin-top: 30px;padding-left: 30px;padding-right: 30px;height: 25px;overflow:hidden;">
                            <a class="mgd" @click="goto('/new',{id: rowNews[0].tb_id})" :title="rowNews[0].tb_title" > {{rowNews[0].tb_title}}</a>
                        </div>
                        <div class="newBigbolck">
                            <a class="mgd"  @click="goto('/new',{id: rowNews[0].tb_id})" > {{rowNews[0].tb_summary}}</a>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="hcell" style="width: 678px;margin-left:48px;">
                    <div class="htable" style="border-bottom: 1px solid #cbcbcb;font-size: 20px;font-weight: bold;height: 40px;text-align: left">
                        <div class="hcell" style="width: 150px;">
                            <span v-if="newsQy"  class="xwLine" >企业新闻</span>
                            <span v-else  class="xwLinew" @click="newsQy=!newsQy;listTopic();">企业新闻</span>
                        </div>
                        <div class="hcell" style="width: 120px;">
                            <span v-if="newsQy"   class="xwLinew" @click="newsQy=!newsQy;listTopic();">行业新闻</span>
                            <span  v-else class="xwLine" >行业新闻</span>
                        </div>
                        <div class="hcell" style="width: 100%;text-align: right;font-size: 16px;font-weight: normal;" >
                            <span class="xwLinew" @click="goto('/news',null)" >更多>></span>
                        </div>
                    </div>
                    <div style="text-align: left;margin-top: 32px;" v-if="rowNews && (rowNews.length>1)">
                        <div class="newitem" style=""   v-for="(n,i) of rowNews.length-1  " :key="'item'+rowNews[i+1].tb_id" >
                            <div class="newitemtim">{{rowNews[i+1].tb_create_time |mmt('YYYY-MM-DD')}}</div>
                            <div class="newitemtxt">
                                <a  @click="goto('/new',{id: rowNews[i+1].tb_id})" > {{rowNews[i+1].tb_title}}</a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section style="margin-top: 80px;">
            <div style="font-size: 36px;font-weight: bold">为什么<span class="tjcpLine" >选择</span>辉腾？</div>
            <div class="body1400 htable" style="margin-top: 100px;" >
                <div class="hcell" style="width: 50%;font-size: 14px;line-height:25px;text-align: left;">
                    辉腾能源秉持 铸平台共创价值，用科技改变生活 的企业使命，依靠科技进步，立足太阳能创新应用，<br/>
                    致力于改变人们生活方式，提高乐享绿色环保生存质量。
                </div>
                <div class="hcell" style="width: 50%;font-size: 30px;font-weight: bold;text-align: right;">
                    铸平台共创价值  用科技改变生活
                </div>
            </div>
            <div class="body1400 htable" style="margin-top: 60px;" >
                <div class="ysback">
                    <div class="ysbktit">服 务</div>
                    <div class="ysbknote">
                        齐全产品线，出厂高标准，满足客户需求，解决客户痛点，<br/>
                        为客户创造价值
                    </div>
                </div>
                <div class="ysback">
                    <div class="ysbktit">价 值</div>
                    <div class="ysbknote">
                        齐全产品线，出厂高标准，满足客户需求，解决客户痛点，<br/>
                        为客户创造价值
                    </div>
                </div>
                <div class="ysback">
                    <div class="ysbktit">专 业</div>
                    <div class="ysbknote">
                        齐全产品线，出厂高标准，满足客户需求，解决客户痛点，<br/>
                        为客户创造价值
                    </div>
                </div>
            </div>
        </section>
        <section style="margin-top: 80px;">
        </section>
        <fdwindow v-if="showZP"/>
    </div>

</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import mybanner from '../components/banner.vue'
    import fdwindow from '../components/fdwindow.vue'
    import mydata from '../static/data/main.json'

    import {listTopicBases,listTopicBase,topicBases,frameBase } from "../hts/api.js"

    import 'video.js/dist/video-js.css'
    import {videoPlayer} from 'vue-video-player'

    export default {
        name: "indexbody",

        components: {
            myhead,
            mybanner,
            videoPlayer,
            fdwindow,
        },
        data() {
            return {
                showZP:false,
                newsQy:true,
                param: {
                    pageindex: 0,
                },

               /* fanganstyle: {
                    'background-image': 'url(' + require('../assets/fangan.png') + ') ',
                    'margin-top': '60px',
                    'height':'850px',
                },*/

                cp1: require('../assets/cp1.png'),
                cp2: require('../assets/cp2.png'),
                cp3: require('../assets/cp3.png'),
                cp4: require('../assets/cp4.png'),
                /*arlefci: require('../assets/arlefci.png'),
                arrigci: require('../assets/arrigci.png'),*/



                playerOptions1: {
                    // videojs options
                    muted: false,
                    language: 'en',
                    playbackRates: [0.8, 1.0, 1.5, 2.0],
                    aspectRatio: '16:9',
                    loop: false,
                    sources: [{
                        type: "video/mp4",
                        /*src: require('../assets/hec.mp4')*/
                    }],
                    /*poster: require('../assets/ebaby_093.jpg'),*/
                },
                manbannner:mydata.manbannner,
                businessData:mydata.businessData,
                tjcpData:mydata.tuijianshangpin,
                rowNews:[],
                businessScheme:mydata.businessScheme,
                businessSchemec:mydata.businessScheme[0],
                gsGy: mydata.gsGy,

            }
        },
        mounted() {

            this.getList()
            this.listTopic()
            //this.getTopicBases ()

            this.getFrameBase()
        },
        methods: {

            getList () {
                // console.log(this.id)
                listTopicBases({type:'newWebsite:[manbannner,businessData,tuijianshangpin,businessScheme]', page: 1, rows: 6 })
                    .then(resData => {
                        this.manbannner=resData.data.manbannner?resData.data.manbannner:[]
                        this.businessData=resData.data.businessData?resData.data.businessData:[]
                        this.tjcpData=resData.data.tuijianshangpin?resData.data.tuijianshangpin:[]
                        this.businessScheme=resData.data.businessScheme?resData.data.businessScheme:[]
                        this.businessSchemec=this.businessScheme[0]
                    })
                    .catch()
            },
            listTopic () {
                // console.log(this.id)
                listTopicBase({fbId:(this.newsQy?'19':'20'), page: 1, rows: 7 })
                    .then(resData => {
                        this.rowNews=resData.data.rows
                        //alert(res.data.rows[0].tb_content)
                        //alert(data.rows[0].tb_content)
                    })
                    .catch()
            },
            getTopicBases () {
                topicBases({ ids: '[449]' })
                    .then(resData => {
                        this.gsGy=resData.data['449'][0]
                    })
                    .catch()
            },
            getFrameBase(){
                frameBase({ id: '98' })
                    .then(resData => {
                        if (resData.data && resData.data.rows[0] && resData.data.rows[0].fb_nav){
                            if (resData.data.rows[0].fb_nav=='1'){
                                this.showZP=true
                            }
                        }
                    })
                    .catch()
            },
            goto(url,query) {
                this.$router.push({ path: url, query: query });
            },
            goscheme(id) {
                this.$router.push({ path: 'scheme', query:{id:id} });
            },
        },
    }
</script>

<style lang="css" scoped>
    map {
        display: none;
        cursor:pointer;
    }
    area{
        outline:none;
    }

    .indexbody {
        /*position: static;*/
       /* height: 500px;*/
        width: 100%;
        text-align: center;
       /* background-color:  #ffffff;*/
    }

    .colmask {
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    @media screen and (max-width: 1270px) {
        .ebaby15 {
            width: 100%;
        }
    }

    @media screen and (max-width: 1662px) {
        .ebaby20 {
            width: 100%;
        }

        .width1662 {
            width: calc((100% - 6px) / 2);
        }

        .width1662_1 {
            width: 100%;
        }

        .width800 {
            width: calc((100% - 6px) / 2);
        }
    }

    @media screen and (max-width: 1202px) {
        .width1202 {
            width: 100%;
        }
    }

    @media screen and (max-width: 800px) {
        .widthsj {
            width: 100%;
        }

        .width1662 {
            width: 100%;
        }

        .width1662_1 {
            width: 100%;
        }

        .width800 {
            width: calc((100% - 6px) / 2);

        }

        .col2 {
            margin-left: 0px;
            width: 100%;
        }
    }

    .border-radius50 {
        border-top-left-radius: 15% 50%;
        border-bottom-left-radius: 15% 50%;
        border-top-right-radius: 15% 50%;
        border-bottom-right-radius: 15% 50%;
    }

    .ljged {
        margin-top: 120px;
        width: 122px;
        height: 34px;
        font-size: 16px;
        color: #444d5a;
        background-color: rgba(255, 255, 255, 0.8);
        line-height: 34px;
    }

    .ibaby-cj {
        width: 100%;
        height: 46px;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
        position: absolute;
        font-size: 16px;
        line-height: 15px;
        margin-top: -50px;
        padding-top: -2px;
        color: rgba(255,255,255,0.9);
    }

    /*播放按钮设置成宽高一致，圆形，居中*/
    .video-button-skin {
        position: absolute;
        background-color: rgba(0,0,0,0.45);
        font-size: 3.5em;
        border-radius: 50%;
        height: 3em !important;
        width: 3em !important;
        line-height: 2em !important;
        margin-top: 23% !important;
        margin-left: 45% !important;
        outline: #e08787;
        border-color: brown;
        border-width: 2px;
    }

    .maincursor {
        cursor:pointer;
    }
    .maincursor:hover {
        background-color: gold;
    }

    .newBigbolck{
        font-size: 14px;
        color: rgba(0,0,0,0.8);
        line-height: 24px;
        margin: 10px  30px 0  30px;
        text-align: left;

        height: 70px;
        overflow:hidden;
        /*
         display:block;
         text-overflow:ellipsis;
         white-space:nowrap;
         */
    }

    .mgd:hover {
        background-color: rgba(203, 203, 203, 1);
        color: rgba(24, 24, 24, 0.9);
        border-radius: 5px;
    }


    .zzcp{
        background-color: #f6f6f6;
        height: 200px;
        width: 233px;
        justify-content: flex-end;
        /*border: 1px solid #d7000f;*/
    }
    .zzcp1{
        margin-left: 50px;
        margin-top: 50px;
        text-align: left;
        border-right:1px solid #d2d2d2;
    }
    .zzcp2{
        font-size: 14px;
    }
    .zzcp3{
        font-size: 40px;
        line-height: 63px;
        color: #d7000f;
    }
    .tjcpLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 30px;

    }
    .xwLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 13px;
    }

    .xwLinew{
        cursor:pointer;
    }

    .xwLinew:hover{
        color: #d7000f;
    }

    .fanblack{

    }
    .fanblack:hover{
        background-color: rgba(255,255,255,0.7);
        cursor:pointer;
    }

    .fangan {
        background-image: url('~assets/fangan.jpg');
        margin-top: 100px;
        height:720px;
    }
    .arleft{
        width: 40px;
        height: 40px;
        background-image: url('~assets/arlefci.png');
    }
    .arleft:hover{
        background-image: url('~assets/arlefcir.png');
        cursor:pointer;
    }
    .arright{
        width: 40px;
        height: 40px;
        background-image: url('~assets/arrigci.png');
    }
    .arright:hover{
        background-image: url('~assets/arrigcir.png');
        cursor:pointer;
    }

    .fa-txt{
        margin-top: 90px;
    }

    .fa-zj{
        width: 234px;
        height:136px;
        /*margin-left: 58px;*/
        background-color: rgba(255,255,255,0.2);
        background-image: url('~assets/fa-zjw.png');
        background-repeat: no-repeat;
        background-position: 87px 20px;
    }
    .fa-zj:hover{
        background-image: url('~assets/fa-zjr.png');
        background-repeat: no-repeat;
        background-position: 87px 20px;
        color: #d7000f;
        cursor:pointer;
    }


    .fa-zm{
        width: 234px;
        height:136px;
        /*margin-left: 58px;*/
        background-color: rgba(255,255,255,0.2);
        background-image: url('~assets/fa-zmw.png');
        background-repeat: no-repeat;
        background-position: 93px 20px;
    }
    .fa-zm:hover{
        background-image: url('~assets/fa-zmr.png');
        background-repeat: no-repeat;
        background-position: 93px 20px;
        color: #d7000f;
        cursor:pointer;
    }

    .fa-ld{
        width: 234px;
        height:136px;
        /*margin-left: 58px;*/
        background-color: rgba(255,255,255,0.2);
        background-image: url('~assets/fa-ldw.png');
        background-repeat: no-repeat;
        background-position: 93px 20px;
    }
    .fa-ld:hover{
        background-image: url('~assets/fa-ldr.png');
        background-repeat: no-repeat;
        background-position: 93px 20px;
        color: #d7000f;
        cursor:pointer;
    }

    .fa-scd{
        width: 234px;
        height:136px;
        /*margin-left: 58px;*/
        background-color: rgba(255,255,255,0.2);
        background-image: url('~assets/fa-scdw.png');
        background-repeat: no-repeat;
        background-position: 94px 20px;
    }
    .fa-scd:hover{
        background-image: url('~assets/fa-scdr.png');
        background-repeat: no-repeat;
        background-position: 94px 20px;
        color: #d7000f;
        cursor:pointer;
    }


    .fa-dy{
        width: 234px;
        height:136px;
        /*margin-left: 58px;*/
        background-color: rgba(255,255,255,0.2);
        background-image: url('~assets/fa-dyw.png');
        background-repeat: no-repeat;
        background-position: 94px 20px;
    }
    .fa-dy:hover{
        background-image: url('~assets/fa-dyr.png');
        background-repeat: no-repeat;
        background-position: 94px 20px;
        color: #d7000f;
        cursor:pointer;
    }
    .newitem{
        cursor:pointer;
        padding-left: 3px;
        padding-right: 3px;
        /*height: 78px;*/
        /*border: 1px solid #d7000f;*/
        /*background-color: rgba(0,0,0,0.1);*/
    }
    .newitem:hover{
        background-color: #ededed;
        color: #d7000f;
        font-weight: bold;
    }
    .newitemtim{
        margin-top: 20px;
        padding-top: 5px;
        font-size: 14px;
    }
    .newitemtxt{
        margin-top: 5px;
        font-size: 18px;
        padding-bottom: 5px;
        /*border: 1px solid #d7000f;*/
    }

    .ysback{
        width: 480px;
        height: 380px;
        background-image: url('~assets/ysbj.png');
        padding-left: 50px;
        /*border: 1px solid #d7000f;*/
    }
    .ysbktit{
        font-size: 36px;
        font-weight: bold;
        text-align: left;
        padding-top: 260px;
    }
    .ysbknote{
        margin-top: 18px;
        font-size: 14px;
        line-height: 22px;
        text-align: left;
    }

    .mgd {
        cursor:pointer;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        border-radius: 5px;
    }
</style>

