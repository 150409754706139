<template >
    <div class="banner">
        <div v-for="(item,index) in param"  :key="index"  v-show="n==index"   >
        <img   :src="item.tb_image"/>

            <div    style="position: absolute;top:310px;left: 959px;"  >
                <div style="text-align: left">

                    <div style="font-size: 56px;font-weight: bold;margin-top: 12px;color: rgba(255,255,255,0.9)">
                        {{item.tb_title}}
                    </div>
                    <div style="font-size: 40px;margin-top: 12px;color: rgba(255,255,255,0.9)" v-html="item.tb_summary"></div>
                </div>
            </div>
        </div>
        <!--
        <div v-for="(item,v) in param" :key="'v'+v"   >
            <div v-if="v==0" style="position: absolute;top:310px;left: 959px;" v-show="n==v">
                <div style="text-align: left">
                    <div style="text-align:center;font-size: 22px;color: rgba(24,24,24,0.8);border: 1px solid red;display: block;width: 130PX;line-height:40px;border-radius: 8px;">
                        HOE-521P
                    </div>
                    <div style="font-size: 56px;font-weight: bold;margin-top: 12px;color: rgba(24,24,24,0.9)">
                        辉腾电宝宝，旅居好电源
                    </div>
                    <div style="font-size: 40px;margin-top: 12px;color: rgba(24,24,24,0.9)">2500W大功率便携式电源</div>
                    <div style="font-size: 22px;margin-top: 36px;color: rgba(24,24,24,0.8)" ><a class="hcursor" @click="goto('/ebaby',{id:'2'})">了解更多></a></div>
                </div>
            </div>
            <div v-else-if="v==1" style="position: absolute;top:278px;left: 360px;" v-show="n==v">
                <div style="text-align: left">
                    <div style="text-align:center;font-size: 22px;color: rgba(215,0,15,0.8);border: 1px solid red;display: block;width: 140PX;line-height:40px;border-radius: 8px;">
                        HOE-521IC
                    </div>
                    <div style="font-size: 56px;font-weight: bold;margin-top: 10px;color: rgba(24,24,24,0.9)">
                        辉腾电磁炉，户外美食家
                    </div>
                    <div style="font-size: 40px;margin-top: 10px;color: rgba(24,24,24,0.9)">太阳能充电电磁炉</div>
                    <div style="font-size: 22px;margin-top: 22px;color: rgba(24,24,24,0.8)"><a class="hcursor" @click="goto('/cooker',{id:'2'})">了解更多></a></div>
                </div>
            </div>
            <div v-else-if="v==2" style="position: absolute;top:350px;left: 755px;" v-show="n==v">
                <div style="text-align: center">
                    <div style="font-size: 65px;font-weight: bold;margin-top: 12px;color: rgba(255,255,255,1);">户外装备  </div>
                    <div style="font-size: 40px;margin-top: 12px;color: rgba(255,255,255,1);">户外神器还需完美伴侣</div>
                </div>
            </div>
            <div v-else-if="v==3" style="position: absolute;top:350px;left: 830px;" v-show="n==v">
                <div style="text-align: center">
                    <div style="font-size: 65px;font-weight: bold;margin-top: 12px;color: rgba(255,255,255,1);">品牌故事</div>
                    <div style="font-size: 40px;margin-top: 12px;color: rgba(255,255,255,1);">电宝宝·传递爱</div>
                </div>
            </div>

            <div v-else-if="v==4" style="position: absolute;top:355px;left: 675px;" v-show="n==v">
                <div style="text-align: center">
                    <div style="font-size: 65px;font-weight: bold;margin-top: 12px;color: rgba(255,255,255,1);">产品攻略</div>
                    <div style="font-size: 40px;margin-top: 12px;color: rgba(255,255,255,1);">玩转电宝宝，请收下这份攻略！</div>
                </div>
            </div>
            <div v-else-if="v==5" style="position: absolute;top:310px;left: 959px;" v-show="n==v">
            <div style="text-align: left">
                <div style="font-size: 65px;font-weight: bold;margin-top: 12px;">人人可做合伙人</div>
                <div style="font-size: 40px;margin-top: 12px;">无需学历、经验、资金也可轻松挣钱</div>
            </div>
        </div>
        </div>
        -->
       <div class="banner-circle">
            <ul>
                <li v-for="(item,index) in param" :key="index" :class="index==n?'selected':''"
                    @click="clickImg(index)"></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            param: {
                type: Array,
                required: true,
            },
        },
        name:"mybanner",
        data(){
            return {
                n:0,//默认图片是第一张开始

            }
        },
        created(){
            this.play()
        },
        methods:{
            play(){
               this.timer = setInterval(this.autoPlay, 5000);//用定时器控制每张图的显示时间
            },
            autoPlay(){
                this.$data.n ++
                if (this.param) {
                    if (this.param.length == this.n) {
                        this.n = 0
                    }
                }
            },
            clickImg(index){
                this.n = index   //显示当前点击的图片
            },
            goto(url,query) {
                this.$router.push({ path: url, query: query });
            },

        },
        beforeDestroy(){
            clearInterval(this.timer)   //清除定时器
        },


    }
</script>

<style lang="css" scoped>
    .banner{
      /*   position:static;*/
        width: 100%;
        text-align: center;
         /*border: 1px solid red;*/
        /*padding: 0px;*/
        /*background-color: #e08787;*/
    }
    img{
        width:100%;
    }
    .banner-circle{
        margin-top: -94px;
        height: 75px;
        padding-left:calc((100% - 370px)/2);
    }
    ul{
        display: flex;
    }
    li{
        height: 12px;
        width: 12px;
        margin-left: 36px;
        border-radius: 50%;
        background:#ffffff;
        list-style-type:none;
    }
    .selected{
        height: 12px;
        width: 24px;
        margin-left: 36px;
        border-top-left-radius: 25% 50%;
        border-bottom-left-radius: 25% 50%;
        border-top-right-radius: 25% 50%;
        border-bottom-right-radius: 25% 50%;
        background: #ffffff;;
        list-style-type:none;
    }

</style>

