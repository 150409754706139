<style lang="less" scoped>
    .fdwindow{
        position: fixed;
        width: 100px;
        height: 100px;
        z-index: 1000;
        left: 100px;
        top: 100px;
        border-radius: 50% ;
        text-align: center;
        cursor:pointer;
    }
    img{
        width: 100px;
        height: 100px;
    }
</style>
<template>
    <div class="fdwindow" :style="'left:'+lefts+'px;top:'+tops+ 'px;'" id="fdwindow">
        <img src="/attached/image/3/ping.png" @click="openwindow"  @mousemove="onmouseover" @mouseout="onmouseout">
    </div>
</template>
<script>
    export default {
        data(){
            return{
                tops:100,
                lefts:100,
                x:2,
                y:2,
            }
        },
        methods:{
            openwindow (){
                 //window.open("/rczp");
                this.$router.push({ path: "/rczp", query: null });
             },
            autoPlay(){
                var clientWidth=document.documentElement.clientWidth-100;
                var clientHeight=document.documentElement.clientHeight-100;
                console.info(clientWidth);
                if (this.lefts>=clientWidth  || this.lefts<=0)
                {
                    this.x=-this.x
                }

                if (this.tops>=clientHeight ||this.tops<=0)
                {
                    this.y=-this.y
                }
                this.lefts+=this.x;
                this.tops+=this.y;
                console.info(this.lefts + "<->"+ this.tops);
            },

            //悬停停止
            onmouseover(){
                clearInterval(this.timer);
            },
            //放手继续运动
            onmouseout(){
                this.play()
            },
            play(){
                this.timer = setInterval(this.autoPlay, 100);//用定时器控制显示时间
            },
        },
        created(){
            this.play()
        },
        beforeDestroy(){
             clearInterval(this.timer)   //清除定时器
        },
    }
</script>

